import React from "react"
import { graphql } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Illustrations = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.illusts" })} />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "illust list" })}
        </h3>
        <div className="main-images mb-8">
          <div className="images grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-3">
            {data.allSvgItemsJson.edges.map(({ node }, index) => (
              <div
                key={index}
                className="image bg-white rounded border border-gray-400 hover:shadow"
              >
                <Link to={"/illustrations/download?q=" + node.name}>
                  <img
                    src={"/images/" + node.cat + "/" + node.name + ".svg"}
                    alt={node.tag}
                    loading="lazy"
                  ></img>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Illustrations

export const query = graphql`
  query {
    allSvgItemsJson(
      sort: { fields: id, order: DESC }
      filter: { cat: { eq: "illustrations" } }
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
    }
  }
`
